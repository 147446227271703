import { px2rem } from '@styles/styleUtils';
import { color } from '@theme/color';
import { device } from '@theme/size';
import styled from 'styled-components';

export const StyleRegister = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${px2rem(25)} ${px2rem(10)};

  h1 {
    font-family: "Messapia", sans-serif;
    font-size: ${px2rem(32)};
    margin-top: ${px2rem(15)};
    margin-bottom: ${px2rem(20)};
  }

  p {
    font-family: "Lexend", sans-serif;
    font-size: ${px2rem(16)};
    margin-bottom: ${px2rem(10)};
  }

  .form-switch {
    color: ${color.red};
    text-decoration: underline;
  }

  .form-paragraph {
    display: flex;
    flex-direction: column;
    margin-bottom: ${px2rem(30)};
  }

  @media ${device.mobileM} {
    padding: ${px2rem(25)} ${px2rem(16)};
  }

  @media ${device.tablet} {
    padding: ${px2rem(30)} ${px2rem(16)} ${px2rem(40)} ${px2rem(16)};
  }

  @media ${device.laptop} {
    & > * {
      width: 100%;
      max-width: ${px2rem(960)};
    }

    .form-paragraph {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;